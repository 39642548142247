$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.rotating-banner__container {
  @include z-index('banner');

  align-self: stretch;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 32px;
}

.rotating-banner__container--sticky {
  position: sticky;
  top: 0;
}

.shadow-user-banner {
  background: $red-200;
  color: $red-400;
  padding: 16px;

  &__text {
    @include font($body-small);

    color: $red-400;

    &--bold {
      @include font($body-small-bold);

      color: $red-400;
    }
  }

  &__button {
    @include font($body-small-bold);

    color: $red-400;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
  }
}
