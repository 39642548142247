$use_maison: true;
.close-button {
  align-items: center;
  border: none;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 0;
  width: 24px;
}
