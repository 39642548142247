$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.skip-link {
  left: -100%;
  position: fixed;
  text-decoration: none;
  top: -100%;
  z-index: 1000;

  @include breakpoint(desktop) {
    @include font($heading-medium);

    background: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 25vw;

    &:hover,
    &:focus {
      align-items: center;
      background-color: $grey-600;
      border-radius: $spacer-xs;
      color: $white;
      display: flex;
      height: 80px;
      justify-content: center;
      left: $spacer-sm;
      outline: $spacer-xs solid $blue-400;
      top: $spacer-m;
    }
  }
}
