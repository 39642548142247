$use_maison: true;
/* stylelint-disable selector-pseudo-class-no-unknown */

// slide up, then down
.slide-up-down:global(.slide-up-down-enter),
.slide-up-down:global(.slide-up-down-appear) {
  opacity: 0;
  transform: translateY(100%);
}

.slide-up-down:global(.slide-up-down-enter-active),
.slide-up-down:global(.slide-up-down-appear-active) {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.slide-up-down:global(.slide-up-down-exit) {
  opacity: 1;
  transform: translateY(0);
}

.slide-up-down:global(.slide-up-down-exit-active) {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}

// slide down, then up
.slide-down-up:global(.slide-down-up-enter),
.slide-down-up:global(.slide-down-up-appear) {
  opacity: 0;
  transform: translateY(-100%);
}

.slide-down-up:global(.slide-down-up-enter-active),
.slide-down-up:global(.slide-down-up-appear-active) {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.slide-down-up:global(.slide-down-up-exit) {
  opacity: 1;
  transform: translateY(0);
}

.slide-down-up:global(.slide-down-up-exit-active) {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms, transform 300ms;
}

// slide right, then left
.slide-right-left:global(.slide-right-left-enter),
.slide-right-left:global(.slide-right-left-appear) {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-left:global(.slide-right-left-enter-active),
.slide-right-left:global(.slide-right-left-appear-active) {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.slide-right-left:global(.slide-right-left-exit) {
  opacity: 1;
  transform: translateX(0);
}

.slide-right-left:global(.slide-right-left-exit-active) {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

// fade in out
.fade-in-out:global(.fade-in-out-enter),
.fade-in-out:global(.fade-in-out-appear),
.fade-in-out--delay:global(.fade-in-out--delay-enter),
.fade-in-out--delay:global(.fade-in-out--delay-appear) {
  opacity: 0;
}

.fade-in-out:global(.fade-in-out-enter-active),
.fade-in-out:global(.fade-in-out-appear-active) {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-in-out--delay:global(.fade-in-out--delay-enter-active),
.fade-in-out--delay:global(.fade-in-out--delay-appear-active) {
  opacity: 1;
  transition: opacity 400ms linear 300ms;
}

.fade-in-out:global(.fade-in-out-exit),
.fade-in-out--delay:global(.fade-in-out--delay-exit) {
  opacity: 1;
}

.fade-in-out:global(.fade-in-out-exit-active),
.fade-in-out--delay:global(.fade-in-out--delay-exit-active) {
  opacity: 0;
  transition: opacity 400ms;
}
